.cardCont {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  overflow: hidden;
}

.imageContainer {
    width: 100%;
    height: 80%;
    overflow: hidden; /* Ensure the image doesn't overflow the container */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the container while maintaining aspect ratio */
  }
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.2); 
  backdrop-filter: blur(10px); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background: #EFAE76;
}

.logo img {
  height: 40px;
}

.links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.links li {
  display: inline;
}

.links a {
  text-decoration: none;
  color: #fff; 
  font-weight: bold;
}

.links a:hover {
  color: #ccc; 
}
